import React from "react";
import "./usefulTools.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
const UsefulTools = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const company = [
    { name: "3.png" },
    { name: "Airtel.png" },
    { name: "AmericaMovil.png" },
    { name: "At&t.png" },
    { name: "CellC.png" },
    { name: "ChinaMobile.png" },
    { name: "EE.png" },
    { name: "Idea.png" },
    { name: "Movistar.png" }, 
    { name: "MTN.png" },
    { name: "O2.png" },
    { name: "Orange.png" },
    { name: "Reliance.png" },
    { name: "Sprint.png" },
    { name: "T-Mobile.png" },
    { name: "TATA.png" },
    { name: "Telefonica.png" },
    { name: "Uninor.png" },
    { name: "Verizon.png" },
    { name: "Vodafone.png" },
  ];
  const settings = {
    rows: 2,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 8,
    slidesToScroll: 7,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // eslint-disable-next-line array-callback-return
  const images = company?.map((e, index) => (
    <div key={index} className="slide-item">
      <img
        loading="lazy"
        src={`/images/logos-company/${e.name}`}
        alt={e.name.split(".")[0]}
        title={e.name.split(".")[0]}
        width="100"
      />
    </div>
  ));
  return (
    <div>
        
        <section className="logos py-md-5 py-3">
        <div className="container-fluid">
          <h3 className="text-center display-6 fw-bold section-title pb-3">
          {t("logosh1")}
          </h3>
          <div className="row logoslider py-md-5">
            <Slider {...settings} id="slick1">
              {images}
            </Slider>
          </div>
        </div>
      </section>  


      <section className="useful-tools py-md-5 pt-4 pb-1">
        <div className="container py-md-4">
          <h2 className="text-center display-6 fw-bold section-title mb-3">
            {t("UsefullH1")}
          </h2>
          <div className="row mt-md-5 imei-colms">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
              <div className="p-4 imei-col rounded p-md-2 p-lg-4">
                <img
                  loading="lazy"
                  className="mt-2"
                  src="../images/imei-blacklist-checker.png"
                  alt="imei-blacklist-checker" width={'40'}
                />
                <h4 className="fw-semibold my-4 fs-5">
                  {t("UsefullH2").replace(/\n/g, "     ")}
                </h4>
                <p className="tool-text mb-1">{t("UsefullP1")}</p>
                <Link to={`checkService/premium-blacklist-checker`} onClick={scrollToTop}>
                  <button className="btn w-100 check-now-btn mb-2">
                    {t("UsefullB1")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
              <div className="p-4 imei-col rounded p-md-2 p-lg-4">
                <img
                  loading="lazy"
                  className="mt-2"
                  src="../images/icloud-activation.png"
                  alt="icloud-activation" width={'40'}
                />
                <h4 className="fw-semibold my-4 fs-5">{t("UsefullH3")}</h4>
                <p className="tool-text mb-1">{t("UsefullP2")}</p>
                <Link
                  to={`iphone-fmi-check`}
                  onClick={scrollToTop}
                >
                  <button className="btn w-100 check-now-btn mb-2">
                    {t("UsefullB1")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
              <div className="p-4 imei-col rounded p-md-2 p-lg-4">
                <img
                  loading="lazy"
                  className="mt-2"
                  src="../images/acc-remove.png"
                  alt="network" width={'40'} height={'40'}
                />
                <h4 className="fw-semibold my-4 fs-5">{t("UsefullH4")}</h4>
                <p className="tool-text mb-1">{t("UsefullP3")}</p>
                <Link to={`samsung-acc-removal`} onClick={scrollToTop}>
                  <button className="btn w-100 check-now-btn mb-2">
                    {t("UsefullB1")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
              <div className="p-4 imei-col rounded p-md-2 p-lg-4">
                <img
                  loading="lazy"
                  className="mt-2"
                  src="../images/icloud-unlock.png"
                  alt="password" width={'40'}
                />
                <h4 className="fw-semibold my-4 fs-5">{t("UsefullH5")}</h4>
                {currentLanguageCode ==="es" &&<><br /></>}
                <p className="tool-text mb-1">{t("UsefullP4")}</p>
                <Link
                  to={`icloud-unlock`}
                  onClick={scrollToTop}
                >
                  <button className="btn w-100 check-now-btn mb-2">
                    {t("UsefullB1")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      
    </div>
  );
};

export default UsefulTools;
